<template>
    <div class="main-content" style="height:100vh; background: #000000; background-repeat: no-repeat;">
        <div class="position-relative">
            <div class="">            
                <img  class="img-newacc mt-5" :src="newacc_success"/>                              
            </div>
        </div>
        <div class="mainpage pt-0">
            <div class="aligh-center">
               <div class="col-sm-14 mb-3 text-center">
                    <div class="mb-2 head_style2" style="font-family: 'Poppins', sans-serif; line-height: 1.4;">
                        {{ $t("newacc_success") }}
                    </div>      
                </div>
                
               <div class="col-sm-12 mb-3 text-center">
                    <p class="mb-2 text-16 text-white" style="font-family: 'Poppins', sans-serif; line-height: 1.3;">
                        {{ $t("newacc_success2") }}
                    </p>      
                </div>  

                <div>  
                    <b-button type="submit" class="mt-5 mb-4 btn-curved-wallet" variant="primary" block
                    :disabled="isLoading">{{ isLoading ? $t("loading...") : $t("ptKYC") }}</b-button>
                </div>

            </div>
        </div>
        <Dialog ref="msg"></Dialog>
    </div>
</template>
  
<script>
import { updateProfile } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import Dialog from "../../../components/dialog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    computed: {
        ...mapGetters(["lang"]),
        fullPIN() {
            return this.digits.join('');
        }
    },
    components: {
        Dialog,
    },
    data() {
        return {
            password: "",
            password_confirmation: "",
            fullname: "",
            email: "",
            contact_number: "",
            myVar: null,
            sending: false,
            isLoading: false,
            digits: ['', '', '', ''],
            newacc_success: require("../../../assets/images/etnex_popup/newacc_success.png"),
        };
    },
    props: ["success"],
    methods: {
        ...mapActions(["signOut"]),
        updateDigit(index, value) {
            // Update the digit at the specified index
            this.digits[index] = value;

            // Move focus to the next input field
            if (index < this.digits.length - 1) {
                this.$refs.inputFields[index + 1].focus();
            }
        },
        goBack() {
            this.signOut();

            this.$router.push("/web/sessions/signIn");
        },
        submitForm() {
            let formData = new FormData();
            var self = this;
            formData.append("fullname", this.fullname);
            formData.append("email", this.email);
            formData.append("contact_number", this.contact_number);
            formData.append("password", this.fullPIN);
            formData.append("password_confirmation", this.fullPIN);
            var result = updateProfile(formData);
            self.isLoading = true;

            result
                .then(function (value) {
                    console.log(value.data);
                    if (value.data.code == 0) {
                        self.$refs.msg.makeToast("success", self.$t(value.data.message));
                        self.password = "";
                        self.password_confirmation = "";
                        setTimeout(() => {
                            var current = location.origin + "/";
                            window.location.href = current + "web";
                        }, 2000);
                    } else {
                        self.$refs.msg.makeToast("danger", self.$t(value.data.message));
                    }
                    self.sending = false;
                    self.isLoading = false;
                })
                .catch(function (error) {
                    self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
                    self.sending = false;
                    self.isLoading = false;
                });
        },
        loadItems() { },
    },
    created() {
        this.loadItems();
        this.username = localStorage.getItem("username");
    },
};
</script>
  
<style scoped>
.main-content {
    max-width: 420px;
    margin: auto;
}

.img-newacc {
    margin-bottom: -90px !important;
    width: 100%;
}
</style>